﻿import { showSidePanel, hideSidePanel } from './helpers/offcanvas.js';

const showLoginPanelIfInvalidError = function () {
    var errorElements = document.querySelectorAll("#login-form .is-invalid");
    if (errorElements.length > 0) {
        showSidePanel("rightPaneLogin");
    }
}
$("#loginMenuButton").on("click", function () {
    var setToVisible = $("#rightPaneLogin[is-shown]").length === 0;
    if (setToVisible) {
        showSidePanel("rightPaneLogin");
    } else {
        hideSidePanel("rightPaneLogin");
    }
});

$("#submit-button-login").on("click", function () {
    document.querySelector("#login-form").submit();
});

$("#submit-button-logout").on("click", function () {
    document.querySelector("#logout-form").submit();
});

document.addEventListener("DOMContentLoaded", function () {
    showLoginPanelIfInvalidError();
})